import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import VueAnimXyz from '@animxyz/vue3';
import '@animxyz/core';
import "@/assets/css/style.less";


createApp(App)
    .use(router)
    .use(VueAnimXyz)
    .mount('#app')