import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView'),
    },
    {
        path: '/requisites',
        name: 'requisites',
        component: () => import('../views/RequisitesGrandView'),
    },
    {
        path: '/agreement',
        name: 'agreement',
        component: () => import('../views/OfferAgreementGrandView'),
    },
    {
        path: '/service/description',
        name: 'description of services',
        component: () => import('../views/ServicesDescriptionView'),
    },

    //
    // {
    //     path: '/MorozovIgorLvovich/requisites',
    //     name: 'requisites_grand',
    //     component: () => import('../views/RequisitesGrandView.vue'),
    // },
    // {
    //     path: '/MorozovIgorLvovich/agreement',
    //     name: 'agreement_grand',
    //     component: () => import('../views/OfferAgreementGrandView.vue'),
    // },
]

const index = createRouter({history: createWebHashHistory(), routes})

// Add a global beforeEach guard
index.beforeEach((to, from, next) => {
  // Scroll to the top of the page before each route is mounted
  window.scrollTo(0, 0);

  // Ensure the rest of the navigation guard process continues
  next();
});

export default index